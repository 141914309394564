body {
  font: 14px 'Open Sans', sans-serif;
  line-height: normal;
  padding: 0;
  margin: 0;
  color: #444;
  background: #111 url("images/background.jpg") center top no-repeat;
  min-height: 100%;
  width: 100%;
  font-weight: 400;
}

@media screen and (max-width: 470px) {
  body {
    background-image: none;
  }
}
